import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-9ad48d1c"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "main"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_empty = _resolveComponent("van-empty");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_empty, {
    description: "感谢你的填写"
  })]);
}